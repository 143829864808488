import service from '@/utils/service'

export function getMenu(params = {}) {
  return service({
    url: `admin/v1/user/getMenu`,
    method: 'get',
    params
  })
}

//获取用户信息
export function getUserInfo(params = {}){
  return service({
    url: `admin/v1/user/getInfo`,
    method: 'get',
    params
  })
}

//获取用户列表
export function getUserList(params = {}){
  return service({
    url: `admin/v1/user/getList`,
    method: 'get',
    params
  })
}

// 修改状态
export function editUserStatus(params:any = {}) {
  return service({
    url: `admin/v1/user/editStatus/`+params.au_user_id,
    method: 'put',
    params
  })
}

// 添加or修改
export function saveUser(data:any = {}) {
  let url = `admin/v1/user/add`;
  let method = 'post';
  if(data.au_user_id > 0){
    url = 'admin/v1/user/edit/'+data.au_user_id;
    method = 'put';
  }
  return service({
    url: url,
    method: method,
    data
  })
}

//删除用户
export function deleteUser(params = {}){
  return service({
    url: `admin/v1/user/delete`,
    method: 'delete',
    params
  })
}

//修改密码
export function updateUserPassword(data:any = {}) {
  return service({
    url: `admin/v1/user/editPassword`,
    method: 'put',
    data
  })
}