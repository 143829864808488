import { createApp } from "vue";
//element 
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/style/global.scss'//引入全局CSS

import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
/** 全局修改全局修改el-table默认样式 start **/
import { ElTable, ElTableColumn } from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'dayjs/locale/zh-cn';
//自定义指令
import setDirectives from "./directives/index";
// 获取组件的props
const TableProps = ElTable.props
const TableColumnProps = ElTableColumn.props

// 修改默认props
// 全局el-table设置

//  highlight-current-row
TableProps.border = { type: Boolean, default: true } // 边框线
TableProps.stripe = { type: Boolean, default: true } // 斑马纹
TableProps.highlightCurrentRow = { type: Boolean, default: true } // 选中高亮

// 全局el-table-column设置
TableColumnProps.align = { type: String, default: 'center' } // 居中
TableColumnProps.headerAlign = { type: String, default: 'center' } // 居中
//TableColumnProps.showOverflowTooltip = { type: Boolean, default: true } // 文本溢出
/** 全局修改全局修改el-table默认样式 end **/



const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
app.use(ElementPlus, { zIndex: 2000, locale: zhCn,size:"small" })//size 用于设置表单组件的默认尺寸，zIndex 用于设置弹出组件的层级，zIndex 的默认值为 2000。
app.use(store)
app.use(router)//路由
app.use(setDirectives)//自定义指令
app.mount("#app");