import type { Directive, DirectiveBinding } from 'vue'
import Store from '@/store/index';


const permission: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding): void {
    // 获取用户所有的权限按钮
    const dataStore:any = Store
    const permissionBtn = dataStore.state.menu.navList.user_button
    // value 获取用户使用自定义指令绑定的内容
    const { value } = binding
    // 判断用户使用自定义指令，是否使用正确了
    if (value && permissionBtn) {
        //判断传递进来的按钮权限，用户是否拥有
        //Array.some(), 数组中有一个结果是true返回true，剩下的元素不会再检测
        const hasPermission = permissionBtn.some((role: any) => {
          return permissionBtn.includes(value)
        })
         // 当用户没有这个按钮权限时，设置隐藏这个按钮
        if (!hasPermission) {
          el.style.display = 'none'
        }
    }
  },
}
 
export default permission