import { getUserInfo } from "@/api/user";
import { messageError } from "@/utils/message";

export const userModule = {
  namespaced:true ,// 命名空间
  state: {
    userinfo: {
      "router":[]
    }, //登陆用户信息
  },
  getters: {},
  mutations: {
    setUserInfo: (state: { userinfo: any; }, info: any) => {
      state.userinfo = info
    },
  },
  actions: {
    setUserInfo: ({ commit }: any) => {
      //异步取用户列表
      return new Promise((resolve) => {
        try {
          getUserInfo()
            .then((res) => {
              console.log('res',res)
              const { data } = res;
              commit("setUserInfo", data);
              //初始密码
              if (data.au_set_password_time == 0) {
                messageError('您使用的是初始密码，请重新设置密码！');
              }
            })
            .catch(() => {
              //Loading.close();
            });
        } catch (error) {
          messageError(`服务端异常：${JSON.stringify(error)}`);
        }
      });
    },
  },
  modules: {},
}