import axios from "axios";

import baseURL from "@/config/baseUrl";
import { ElMessage } from "element-plus";
import { messageError } from "@/utils/message";
import { getLocalStorage, removeLocalStorage } from "@/utils/localStorage";
import router from "@/router/index";

//创建一个Axios实例，并设置默认的配置参数
const service = axios.create({
  baseURL: baseURL.requestUrl + baseURL.apiPrefix, // baseURL会自动加在请求地址上
  timeout: 30000, // 10秒 表示请求的超时时间
});

//请求拦截器
service.interceptors.request.use(
  (config) => {
    const token = getLocalStorage("Authorization");
    config.headers["Content-Type"] = "application/json";
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    messageError(JSON.stringify(error));
    // return Promise.reject(error)
    return;
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    console.log('res',res)
    if (res.code == 401 || res.code == 403) {
      removeLocalStorage("Authorization");
      router.push("/admin/login");
      ElMessage.closeAll();
      return Promise.reject(new Error(res.msg));
    } else if (res.code == 200) {
      return res;
    } else if (res.code == 0) {
      messageError(res.msg);
      return Promise.reject(new Error(res.msg)); // 此处没有错误对象，故创建error实例，返回执行错误 让当前的执行链跳出成功 直接进入 catch
    } else {
      return Promise.reject(res.msg);
    }
  },
  (error) => {
    console.log('error',error)
    ElMessage.closeAll();
    messageError(`服务端异常：${JSON.stringify(error)}`);
  }
);

export default service;
