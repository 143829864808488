import { ElMessage } from 'element-plus';

//成功提示
export function messageSuccess(msg:string) {
  ElMessage.success({
		message: msg,
		showClose: true,
		type: 'success',
	});
}

//错误提示
export function messageError(msg:string) {
  ElMessage.error({
    message: msg,
    showClose: true,
    type: 'error'
  });
}

