import service from '@/utils/service'

// 登录
export function login(data = {}) {
  return service({
    url: `admin/v1/auth/login`,
    method: 'post',
    data
  })
}

// 登出
export function logout(params = {}) {
  return service({
    url: `admin/v1/auth/logout`,
    method: 'post',
    params
  })
}
