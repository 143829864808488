import { Directive} from 'vue'
const autoSelect: Directive = {
  beforeMount(el:any) {
    const selectWrap = el.querySelector('.el-input__wrapper .el-input__inner');
    // 监听获得焦点事件
    selectWrap.addEventListener('focus', () => {
      selectWrap.select();
    });
  }
}
export default autoSelect