import type { App } from 'vue'
import permission from './modules/permission'
import scroll from './modules/scroll'
import autoSelect from './modules/autoSelect'
 
const directivesList: any = {   //汇总自定义指令
  permission,//按钮权限
  scroll,//滚动条
  autoSelect,//自动选中
}

const setDirectives = {
  install(app: App<Element>) { // 以安装的方式插到app中
    Object.keys(directivesList).forEach((key) => {  // 遍历directives对象的key
      app.directive(key, directivesList[key])       // 将每个directive注册到app中
    })
  },
}
 
export default setDirectives //导出自定义指令