import { ElLoading } from 'element-plus'
//默认加载
export function loading() {
  const loadingInstance = ElLoading.service({
    text:"Loading...",
    lock:true,
    background:"rgba(255, 255, 255, 0.7)",
    fullscreen:false,
    target:".view-box",
  })
  return loadingInstance;
}

//表格加载
export function formLoading(){
  const loadingInstance = ElLoading.service({
    text:"Loading...",
    lock:true,
    background:"rgba(255, 255, 255, 0.7)",
    fullscreen:false,
    target:".el-dialog .el-dialog__body",
  })
  return loadingInstance;
}

//通过ID加载
export function targetLoading(target:string){
  const loadingInstance = ElLoading.service({
    text:"Loading...",
    lock:true,
    background:"rgba(255, 255, 255, 0.7)",
    fullscreen:false,
    target:target,
  })
  return loadingInstance;
}

//表格加载
export function tableLoading(){
  const loadingInstance = ElLoading.service({
    text:"Loading...",
    lock:true,
    background:"rgba(255, 255, 255, 0.7)",
    fullscreen:false,
    target:".page-middle",
  })
  return loadingInstance;
}

//铺满全屏加载
export function fullscreenLoading() {
  const loadingInstance = ElLoading.service({
    text:"Loading...",
    lock:true,
    background:"rgba(255, 255, 255, 0.7)",
    body:true,
  })
  return loadingInstance;
}