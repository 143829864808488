import { getConfig, getDict } from "@/api/basic";
import { messageError } from "@/utils/message";

export const configModule = {
  namespaced: true, // 命名空间

  state: {
    configInfo: {}, //系统配置信息
    dictInfo: {}, //字典数据信息
    userData: {}, //正常用户列表
  },

  getters: {},

  mutations: {
    //设置系统配置信息
    setConfigInfo: (state: { configInfo: any }, info: any) => {
      state.configInfo = info;
    },
    //设置字典数据信息
    setDictInfo: (state: { dictInfo: any }, info: any) => {
      state.dictInfo = info;
    },
    //设置所有正常用户信息
    setUserList: (state: { userData: any }, info: any) => {
      state.userData = info;
    },
  },

  actions: {
    getConfiglist: ({ commit }: any) => {
      //异步取配置信息
      return new Promise((resolve) => {
        try {
          getConfig()
            .then((res) => {
              const { data } = res;
              console.log('getConfig',res)
              commit("setConfigInfo", data);
            })
            .catch(() => {
              //Loading.close();
            });
        } catch (error) {
          messageError(`服务端异常：${JSON.stringify(error)}`);
        }
      });
    },
    getDictList: ({ commit }: any) => {
      //异步取字典数据信息
      return new Promise((resolve) => {
        try {
          getDict()
            .then((res) => {
              const { data } = res;
              console.log('getDict',res)
              commit("setDictInfo", data);
            })
            .catch(() => {
              //Loading.close();
            });
        } catch (error) {
          messageError(`服务端异常：${JSON.stringify(error)}`);
        }
      });
    },
    // getUserList: ({ commit }: any) => {
    //   //异步取用户列表
    //   return new Promise((resolve) => {
    //     try {
    //       getUser()
    //         .then((res) => {
    //           console.log('getUser',res)
    //           const { data } = res;
    //           commit("setUserList", data);
    //         })
    //         .catch(() => {
    //           //Loading.close();
    //         });
    //     } catch (error) {
    //       messageError(`服务端异常：${JSON.stringify(error)}`);
    //     }
    //   });
    // },
  },

  modules: {},
};
