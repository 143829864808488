import { createStore } from "vuex";
import {menuModule} from './modules/menu'
import {userModule} from './modules/user'
import {configModule} from './modules/config'

import createPersistedState from "vuex-persistedstate";//导入库
export default createStore({
  modules:{
    menu:menuModule,
    user:userModule,
    config:configModule
  },
  plugins: [createPersistedState({
    key: 'plugins',
    paths: ['menu','user','config'] // 这里便只会缓存user下的state值
  })]
});

