import axios from 'axios';

// 路由判断
function handleSetSelect(list: any, route: any, store: any) {
  console.log("handleSetSelect::list", list);
  if (!list) {
    return false;
  }
  const path = route.currentRoute.value.path;
  console.log("path", path);
  if (path == "/" || path == "/admin/home") {
    store.commit("menu/setRouterNavList", list[0].child);
    store.commit("menu/setSelectIndex", 0);
  } else {
    //默认先打开一个、避免四级菜单找不到导致左侧为空
    store.commit("menu/setRouterNavList", list[0].child);
    store.commit("menu/setSelectIndex", 0);
    list.forEach((el: any, i: number) => {
      if (el.child) {
        el.child.forEach((ev: any) => {
          if (ev.child) {
            ev.child.forEach((ec: any) => {
              console.log('ec.am_path==path',ec.am_path+'==='+path)
              if (ec.am_path == path) {
                console.log('el.child11111111',el.child)
                store.commit("menu/setRouterNavList", el.child);
                store.commit("menu/setSelectIndex", i);
              }
            });
          }
        });
      }
    });
  }
}


export { handleSetSelect };
