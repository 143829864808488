
const scroll = {
  beforeMount(el:any, binding:any) {
    // 确保el是一个Element或者Component实例
    //if (!(el instanceof Element || el instanceof ComponentPublicInstance)) {
    // if (!(el instanceof Element)) {
    //   console.error('v-scroll can only be used on Element or Component instances');
    //   return;
    // }
    const selectWrap = el.querySelector('.el-table__body-wrapper .el-scrollbar__wrap');
    // 监听滚动事件
    selectWrap.addEventListener('scroll', binding.value);
  },
  unmounted(el:any, binding:any) {
    // 组件销毁时移除事件监听
    el.removeEventListener('scroll', binding.value);
  },
};
 
export default scroll