import service from '@/utils/service'

// 清除缓存
export function cacheFlush () {
  return service({
    url: `common/v1/basic/cacheFlush`,
    method: 'post',
  })
}

// 获取配置
export function getConfig () {
  return service({
    url: `common/v1/basic/config`,
    method: 'get',
  })
}

// 获取配置
export function getDict () {
  return service({
    url: `common/v1/basic/dict`,
    method: 'get',
  })
}

// 获取操作日志列表
export function getLog (data: any = {}) {
  return service({
    url: `common/v1/basic/operate_log`,
    method: 'post',
    data
  })
}